import styled from '@emotion/styled';
import { COLORS } from '../../../constants/colors';
import { CheckBlock, Checked } from '../../pages/create-new/MaterialsChaptersAndRatio';
import {
  FakeHighLightToolTip,
  FakeTooltip,
  IconWrapper,
  MenuHeaderWrapper,
} from '../../ui-components/Menu/MenuComponents.styled';
import Button from '../../ui-components/Button';

export const MusicMenuBlock = styled.div`
  position: relative;
  width: 100%;
  padding: 6px 10px 18px 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  &:after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    left: 5px;
    right: 5px;
    height: 0.4px;
    background-color: ${COLORS.peech_gray_300};
  }
`;

export const MusicMenuButton = styled.button<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: unset;
  background-color: unset;

  svg {
    fill: ${COLORS.peech_gray_700};
  }
  cursor: ${({ disabled }): string => (disabled ? 'unset' : 'pointer')};

  &:hover {
    svg {
      fill: ${({ disabled }): string => (disabled ? COLORS.peech_gray_700 : COLORS.peech_primary_2_500)};
      transition: fill 250ms ease-out;
    }
  }
  &:active {
    svg {
      fill: ${({ disabled }): string => (disabled ? COLORS.peech_gray_700 : COLORS.peech_gray_900)};
      transition: fill 250ms ease-out;
    }
  }
`;

export const AudioContainer = styled.div`
  width: 100%;
  height: calc(100vh - 56px);
  position: relative;
  font-family: 'Open Sans';
  color: ${COLORS.peech_neutral_black};

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const MusicMenuContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 600px;
  padding: 10px;
  background-color: inherit;

  ${MenuHeaderWrapper} {
    &:after {
      left: 5px;
      right: 5px;
    }
  }

  ${FakeTooltip} {
    width: 100%;
  }
  ${FakeHighLightToolTip} {
    width: 100%;
  }
`;

export const MusicMenuWrapper = styled.div<{ blured: boolean }>`
  opacity: ${({ blured }): string => (!blured ? '1' : '0.5')};
  pointer-events: ${({ blured }): string => (!blured ? 'all' : 'none')};
`;

export const StockLibraryContainer = styled.div<{ openStockLibrary: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 520px;
  padding: 10px;
  background-color: ${COLORS.peech_neutral_white};
  z-index: 10;
  translate: ${({ openStockLibrary }): string => (openStockLibrary ? '0' : '100%')};
  transition: translate 0.5s;
`;

export const DenoiseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
    margin-top: 18px;
`;

export const DenoiseDiv = styled(MusicMenuBlock)<{ disabled: boolean }>`
  flex-direction: row;   
  align-items: center;
  justify-content: space-between;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;

  span {
    justify-content: flex-start;
    opacity: ${({ disabled }): string => (disabled ? '0.4' : '1')};
    svg {
      width: 18px;
      margin-top: 2px;
      margin-right: 2px;
    }
    label {
      font-weight: 400;
    }
  }

  &:after {
    display: none;
  }
`;
export const UseMusicDiv = styled.div`
  position: relative;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  border: unset;

  &:after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    left: 7%;
    right: 7%;
    height: 0.4px;
    background-color: ${COLORS.peech_gray_300};
  }
`;
export const AudioTrackDiv = styled(MusicMenuBlock)`
  flex-direction: column;
`;
export const ActionsDiv = styled.div`
  width: 100%;
  padding-inline: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
    
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: flex-start;
    margin-top: 28px;
`;
export const ButtonsDiv = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: row;
`;
export const OpenStockButton = styled(MusicMenuButton)`
  border-right: solid 1px;
  border-color: ${COLORS.peech_gray_300};
  padding: 0 10px;
  width: fit-content;
  height: 100%;
`;
export const UploadButton = styled(OpenStockButton)`
  border-right: unset;
`;

export const BluredDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.peech_neutral_white};
  border-radius: 4px;
  padding: 0;
  z-index: 2;
  animation: taadaa 2s infinite;
`;

export const MusicTrack = styled.div`
  position: relative;
  margin-top: 15px;
  width: 100%;
  background-color: ${COLORS.peech_gray_50};
  border-radius: 4px;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px 10px 15px;

  svg {
    fill: ${COLORS.peech_gray_700};
  }
`;

export const ChangeTrackButton = styled(MusicTrack)<{ disabled: boolean }>`
  margin-top: 8px;
  margin-bottom: 8px;
  margin-top: 0;
  margin-bottom: 0;
  justify-content: flex-start;

  svg {
    width: 18px;
  }

  cursor: ${({ disabled }): string => (disabled ? 'unset' : 'pointer')};
  color: ${COLORS.peech_neutral_black};

  &:hover {
    color: ${({ disabled }): string => (disabled ? COLORS.peech_neutral_black : COLORS.peech_primary_2_500)};
    svg {
      fill: ${({ disabled }): string => (disabled ? COLORS.peech_gray_700 : COLORS.peech_primary_2_500)};
      transition: fill 250ms ease-out;
    }
  }
  &:active {
    color: ${({ disabled }): string => (disabled ? COLORS.peech_neutral_black : COLORS.peech_gray_900)};
    svg {
      fill: ${({ disabled }): string => (disabled ? COLORS.peech_gray_700 : COLORS.peech_gray_900)};
      transition: fill 250ms ease-out;
    }
  }

  transition: color 250ms ease-out;
`;

export const MusicTitle = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 148px;
  margin: 0 5px 0 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const LoaderContainer = styled.div`
  width: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const MusicItem = styled(MusicTrack)<{ isCurrent?: boolean; isPlaying?: boolean }>`
  cursor: pointer;
  svg {
    fill: ${({ isPlaying }): string => (isPlaying ? COLORS.peech_primary_2_500 : COLORS.peech_gray_700)};
    transition: fill 250ms ease-out;
  }

  &:hover {
    svg {
      fill: ${COLORS.peech_primary_2_500};
      transition: fill 250ms ease-out;
    }
  }

  ${MusicTitle} {
    color: ${({ isPlaying }): string => (isPlaying ? COLORS.peech_primary_2_500 : COLORS.peech_gray_700)};
  }

  ${CheckBlock} {
    position: relative;
    bottom: unset;
    left: unset;
    width: 20px;
    max-width: 20px;
    min-width: 20px;
    height: 20px;
    max-height: 20px;
    min-height: 20px;
    border-color: ${({ isCurrent }): string => (!isCurrent ? COLORS.peech_gray_400 : COLORS.peech_primary_2_500)};
  }

  ${Checked} {
    background-color: ${COLORS.peech_primary_2_500};
    visibility: ${({ isCurrent }): string => (!isCurrent ? 'hidden' : 'visible')};
  }

  @keyframes taadaa {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }
`;

export const MusicVolumeDiv = styled(MusicMenuBlock)`
  position: relative;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  flex-direction: column;
  .slider .slider-circle {
    width: 24px;
    height: 24px;
    cursor: grab;
  }
`;

export const GeneratingMusicWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BluredPlug = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.peech_neutral_white};
  opacity: 0.7;
`;

export const GeneratingMusicLoader = styled.div`
  width: 140px;
  height: 98px;
  border-radius: 10px;
  background-color: ${COLORS.peech_gray_100};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;

  span {
    margin-top: 8px;
  }

  transform: scale(0);
  animation: appear 0.5s ease 0.2s forwards;

  @keyframes appear {
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const ApplyButton = styled(Button)`
  width: 100% !important;
  height: 36px !important;
  font-size: 14px !important;
  margin-bottom: 20px;
`;

export const SliderWrapper = styled.div`
  margin-top: 16px;
`;

export const ChangeTrackDiv = styled(MusicMenuBlock)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  padding-top: 3px;
`;

export const BreakDiv = styled.div`
  height: 16px;
`;

export const RevertVolumeButton = styled(MusicMenuButton)`
  position: absolute;
  top: 0px;
  right: 12px;
  width: 30px;
  height: 30px;
  svg {
    transition: fill 250ms ease-out;
  }
`;
export const MusicLibraryHeader = styled(MusicMenuBlock)`
  position: relative;
  font-weight: 700;
  font-size: 14px;
  padding-top: 10px;
  ${MusicMenuButton} {
    margin-right: 18px;
  }
`;
export const DiamondContiner = styled.div`
  height: 80%;
  width: 20%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    width: 28px;
  }
`;
export const SearchMusicDiv = styled(MusicMenuBlock)`
  flex-direction: column;
`;
export const SearchingContainer = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-right: 8px;
  }

  input {
    width: 100%;
    height: 24px;
    border: 0.5px solid;
    border-color: ${COLORS.peech_gray_400};
    border-radius: 3px;
    background-color: ${COLORS.peech_neutral_white};
    padding: 3px 22px 5px 10px;
    color: ${COLORS.peech_gray_900};
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    &::placeholder {
      color: ${COLORS.peech_gray_400};
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 12px;
    }
  }
  input:focus {
    outline: none !important;
    border: 1px solid ${COLORS.peech_gray_700};
    box-shadow: 0 0 1px ${COLORS.peech_gray_700};
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      margin: 8px 0 0 0;
      fill: ${COLORS.peech_gray_700};
    }

    &:hover {
      svg {
        fill: ${COLORS.peech_primary_2_500};
      }
    }
    &:active {
      svg {
        fill: ${COLORS.peech_gray_900};
      }
    }
  }
`;
export const TagsContainer = styled(SearchingContainer)`
  margin-top: 18px;
  flex-wrap: wrap;
  height: auto;
  max-height: 60px;
  overflow-y: scroll;
`;
export const Tag = styled.div`
  height: 18px;
  width: fit-content;
  min-width: 42px;
  max-width: 92%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 3px 6px;
  margin: 3px;
  border-radius: 4px;
  background-color: ${COLORS.peech_gray_100};
  color: ${COLORS.peech_gray_400};
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: ${COLORS.peech_gray_900};
  }
  &:active {
    background-color: ${COLORS.peech_gray_300};
  }
  transition: color 250ms ease-out;
`;
export const MusicLibraryFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 14px;
  left: 0px;
  padding: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 14px;

  ${FakeTooltip} {
    text-align: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 16px;

    ${IconWrapper} {
      margin-right: 20px;
    }
  }
`;
export const MusicLibraryListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(100vh - 400px);
  min-height: 200px;
  overflow-y: scroll;
  padding-left: 10px;
`;

export const EmptyTrackList = styled.div`
  width: 100%;
  text-align: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: ${COLORS.peech_gray_600};
`;
